<template>
  <div class="thankyou">
    <template v-if="!isPrakerja">
      <div class="container mx-auto py-20 text-center mdmax:py-10 mdmax:px-4">
        <ImageItem :source="require('@/assets/images/illustration/Ok.svg')" />
        <div class="text-3xl font-bold mt-6 mb-4 mdmax:text-lg" v-if="isWaitingList">Thank you for booking our program!</div>
        <div class="text-3xl font-bold mt-6 mb-4 mdmax:text-xl" v-else>Thank you for {{ isShortCourse ? 'Signing Up' : 'signing up enrolling!' }}</div>
        <div class="mdmax:text-sm">
          <p class="mb-4" v-if="isBootcmap">We will send you an email with all the details. Looking forward to learn new things with you!</p>
          <p class="mb-4" v-else-if="isBookSession || isOpenDay">
            An invitation will be sent to your email with all the details. Looking forward to meeting you at the
            {{ isBookSession ? 'Online Private Consultation' : isOpenDay ? 'Open Day' : 'Orientation' }}!
          </p>
          <p class="mb-4 mdmax:text-xs" v-else>
            {{
              isWaitingList
                ? 'You are now listed as one of our potential students. Our Customer Service will reach out to you to confirm your booking status. Please check your email to find the detailed information. See you on top!'
                : 'An invitation will be sent to your email with all the details.'
            }}
          </p>
          <router-link to="/" class="text-yellow">Click here to return to Home.</router-link>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="container mx-auto py-20 px-14 mdmax:py-10 mdmax:px-4 mdmax:flex-col flex justify-between">
        <div class="mx-auto mdmax:py-10 mdmax:px-4">
          <ImageItem :source="require('@/assets/images/illustration/Ok.svg')" />
        </div>
        <div class="mx-auto mdmax:px-4">
          <div class="text-3xl font-bold mt-6 mb-4 mdmax:text-xl">Terima kasih telah mendaftar kelas prakerja!</div>
          <p v-if="thankYouPageTitle.title">"{{ thankYouPageTitle.title }}"</p>
          <p class="mb-5">Kami telah menerima data pendaftaran Anda. Untuk langkah selanjutnya:</p>
          <p class="ml-3">1. Silakan <strong>cek email anda</strong> untuk detail kelas Prakerja yang anda daftar.</p>
          <p class="ml-6 text-red-500">Notes: Bila belum menerima email dalam waktu 15 menit, harap hubungi</p>
          <p class="ml-6 mb-4 text-red-500">Admin terkait</p>
          <p class="ml-3 mb-5">2. Bila sudah menerima email pendaftaran, silakan mengikuti kelas Prakerja.</p>
          <p class="mb-3">Untuk informasi lainnya, silakan hubungi kami <router-link to="/contact" class="text-yellow">di sini</router-link>.</p>
          <router-link to="/" class="text-yellow">Klik di sini untuk kembali ke Beranda.</router-link>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name: 'thank-you',
  components: {
    ImageItem: () => import(/* webpackChunkName: "image" */ '@/components/image/ImageItem')
  },
  computed: {
    ...mapGetters('common', ['thankYouPageTitle']),
    classType() {
      return this.$route.params.class
    },
    isBookSession() {
      return this.classType === 'book-session'
    },
    isOpenDay() {
      return this.classType === 'open-day' || this.classType === 'bootcamp'
    },
    isPrakerja() {
      return this.classType === 'prakerja'
    },
    isBootcmap() {
      return this.classType === 'bootcamp'
    },
    isWaitingList() {
      return this.classType === 'waiting-list'
    },
    isShortCourse() {
      return this.classType === 'short-course'
    }
  }
}
</script>
